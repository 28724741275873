const { generateUUID } = require("../lib/uuid");
const { createJSONQuery } = require("../lib/json");

export function reorderSidebarComments() {
  const comments = document.querySelectorAll(".ld-comment-card");
  let lastBottom = 0;

  const rail = document.getElementById("ld-page-comments");

  if (rail == null) {
    return;
  }
  const railY = rail.getBoundingClientRect().top;

  comments.forEach(comment => {
    const linkedId = comment.getAttribute("data-target-comment");
    const isnew = comment.getAttribute("data-new-comment");
    const linkedElement = document.querySelector(`[data-comment-id="${linkedId}"]`);

    let topPosition = null;
    if (linkedElement) {
      const rect = linkedElement.getBoundingClientRect();
      topPosition = rect.top;
    }

    const fixedY = comment.getAttribute("data-fixed-y");
    if (fixedY != null) {
      topPosition = parseFloat(fixedY);
    }

    if (topPosition == null) {
      return;
    }
    topPosition -= railY;

    comment.setAttribute("data-wanted-y", topPosition);
  });

  const commentsArray = Array.from(comments);

  commentsArray.sort(function(a, b) {
    return parseInt(a.getAttribute("data-wanted-y"), 10) - parseInt(b.getAttribute("data-wanted-y"), 10);
  });

  commentsArray.forEach(comment => {

    let topPosition = parseFloat(comment.getAttribute("data-wanted-y"));

    const linkedId = comment.getAttribute("data-target-comment");

    if (topPosition < lastBottom + 10) { // 10px gap between comments
      topPosition = lastBottom + 10;
      console.log(`new top = ${topPosition}`);
    }

    comment.style.position = "absolute";
    comment.style.top = `${topPosition}px`;

    lastBottom = topPosition + comment.offsetHeight;
  });

};

const supportComments = document.querySelector(".ld-comments-controller") != null;

if (supportComments) {

  document.addEventListener("selectionchange", () => {
    if (window.disableSelectionProcessing) {
      console.log("disableSelectionProcessing = true");
      return;
    }
    //  console.log("selectionChange");
    $(".ld-unused-comment").remove();
    const selection = window.getSelection();
    if (selection.rangeCount > 0 && selection.toString().length > 0) {
      const range = selection.getRangeAt(0);
      let commonAncestor = range.commonAncestorContainer;

      // If the common ancestor is not an element (e.g., it's a text node), get its parent element
      if (commonAncestor.nodeType !== Node.ELEMENT_NODE) {
        commonAncestor = commonAncestor.parentNode;
      }

      const parentElement = commonAncestor.closest(".ld-selectable-content");

      if (!parentElement) {
        console.log("Selection is not within .ld-selectable-content");
        return;
      }

      const summaryParent = commonAncestor.closest(".ldx-summary");
      if (summaryParent) {
        console.log("Selection within summary");
        return;
      }

      const editorParent = commonAncestor.closest(".ql-snow");
      console.log("edp", editorParent);
      if (editorParent) {
        console.log("Selection within editor");
        return;
      }

      console.log("Try to create comment for", selection.toString());

      // window.selectedRange = range;

      const rect = range.getBoundingClientRect();

      // Get the (x, y) coordinates relative to the viewport
      const x = rect.left;
      const y = rect.top;

      console.log("x,y", x, y);

      const uuid = `comment://${generateUUID()}`;
      console.log("genereate", uuid);
      //this.quill.format("comment_sidebar_entry", uuid);

      const sidebar = $("#ld-page-comments");//$(this.quill.root).parents(".ld-jseditor-form").find(".ui.right.rail");
      //console.log("sidebar",sidebar);

      const temp = document.querySelector("#ld-comment-card-template");
      const clon = temp.content.cloneNode(true);
      console.log("CLON", clon);

      const card = $(clon).find(".ui.card");

      card[0].setAttribute("data-target-comment", uuid);
      card[0].setAttribute("data-new-comment", "true");
      card[0].setAttribute("data-fixed-y", y);

      card[0].ldCommentRange = range;
      card[0].ldCommentCreated = false;

      $(clon).find(".ui.card textarea").on("input", function() {
        card.removeClass("ld-unused-comment");
        console.log("my range is", card[0].ldCommentRange, card[0].ldCommentCreated);
        if (!card[0].ldCommentCreated) {
          console.log("will start creating an actual comment...");

          window.disableSelectionProcessing = true;

          const boldTag = document.createElement("span");
          $(boldTag).addClass("ldx-sidebar-entry");
          boldTag.setAttribute("data-comment-id", uuid);
          card[0].removeAttribute("data-fixed-y");

          // Extract the contents of the selected range
          const selectedContent = range.extractContents();

          // Append the selected content into the <b> tag
          boldTag.appendChild(selectedContent);

          // Insert the <b> tag back into the range's position
          range.insertNode(boldTag);

          card[0].ldCommentCreated = true;

          window.disableSelectionProcessing = false;

          console.log("did create an actual comment");
        }

      });
      card.on("click", function() {
        console.log("klik me!");
      });
      $(clon).find(".ui.card textarea").on("focus", function() {
        console.log("fokus!");
      });
      card.on("mousedown", function(event) {
        console.log("myszdół");
        card.removeClass("ld-unused-comment");
        document.getSelection().removeAllRanges();
      });

      sidebar[0].appendChild(clon);

      reorderSidebarComments();

      return;

      // Create a new <b> element

    }
  });

  $(document).on("click", ".ld-send-new-comment-button", function(event) {
    const button = this;
    // console.log("button", button);
    const card = $(button).parents(".ui.card");
    const url = card.attr("data-submit-url");

    const commentId = card.attr("data-target-comment");
    const textarea = card.find("textarea");

    const originalNode = document.querySelector("#wiki-content");

    const clonedNode = originalNode.cloneNode(true);

    const buttonsToRemove = clonedNode.querySelectorAll(".ld-html-skip-export");
    buttonsToRemove.forEach(button => button.remove());

    let concatenatedHTML = "";
    const elementsToInclude = clonedNode.querySelectorAll(".ld-html-export");
    elementsToInclude.forEach(element => {
      element.querySelectorAll("*").forEach(node => node.classList.remove("active"));
      concatenatedHTML += element.innerHTML;
    });

    const html = concatenatedHTML;

    console.log("html");
    console.log(html);

    const commentText = textarea.val();

    const data = {
      page: {
        html: html,
        new_comment: {
          id: commentId,
          text: commentText
        }
      }
    };
    console.log("submit", data, "to", url);

    const query = createJSONQuery(data);

    console.log("query", query);

    card.addClass("loading");

    fetch(url, query)
      .then(response => response.json()) // Parse JSON response
      .then(result => {
        card.removeClass("loading");
        textarea.remove();
        card.find(".ld-comment-text").removeClass("ld-comment-text");
        card.find(".description").text(commentText);
        card.find(".ld-send-new-comment-button").remove();
        card.addClass("ld-newly-submitted-comment");

        console.log("Success:", result);
        console.log("ld-newly-submitted-comment: ", document.querySelectorAll(".ld-newly-submitted-comment"));
      })
      .catch(error => {
        console.error("Error:", error);
      });

  });

}

export function startHandlingCommentsMouseOver() {

  Object.entries(  {
    mouseover: "add",
    mouseout: "remove"
  }).forEach(([eventName, classMethod], index) => {
    console.log("en", eventName, "cm", classMethod);

    document.addEventListener(eventName, function(event) {
      let actor = event.target.closest(".ld-comment-card");
      if (actor) {
        const targetComment = actor.dataset.targetComment;
        const css = `.ldx-sidebar-entry[data-comment-id="${targetComment}"]`;
        document.querySelectorAll(css).forEach(entry => {
          entry.classList[classMethod]("active");
        });
      }
      actor = event.target.closest(".ldx-sidebar-entry");
      if (actor) {
        const targetComment = actor.dataset.commentId;
        const css = `.ld-comment-card[data-target-comment="${targetComment}"]`;
        document.querySelectorAll(css).forEach(entry => {
          entry.classList[classMethod]("ld-active-card");
        });
      }
    });
  });
}
