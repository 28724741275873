const { createJSONQuery } = require("../lib/json");
import { createDatetimeHTML } from "../lib/date";

const abortController = new AbortController();
const abortSignal = abortController.signal;
let autosaveTimeout = null;

function autosave() {
  const element = document.querySelector(".lsjs-autosave-controller");
  console.log("try autosave", element);
  if (!element) {
    return;
  }
  const interval = element.getAttribute("data-interval") || 60;
  const url = element.getAttribute("data-url");

  const infobox = document.querySelector("#ld-autosave-infobox");
  const date = new Date(Date.now() + interval * 1000);
  infobox.innerHTML = `Next autosave in ${createDatetimeHTML(date)}`;
  autosaveTimeout = setTimeout(() => {

    infobox.innerHTML = "<i class=\"asterisk loading icon\"></i> Autosaving…";

    const html = document.querySelector("#wiki-content .ql-editor").innerHTML;

    const data = {
      page: {
        html: html,
        autosave: true
      }
    };

    const query = createJSONQuery(data);
    query.method = "PATCH";
    query.signal = abortSignal;

    console.log("query", query);

    fetch(url, query)
      .then(response => response.json()) // Parse JSON response
      .then(result => {
        autosave();
      })
      .catch(error => {
        console.error("Error:", error);
      });
  }, interval * 1000);

}

export function startAutosaving() {
  document.addEventListener("DOMContentLoaded", () => {
    const abortable = document.querySelector(".ld-autosave-abortable-form");
    if (abortable) {
      const form = abortable.querySelector("form");
      if (form) {
        console.log("arm autosave form handler", form);
        form.addEventListener("submit", function(event) {
          console.log("form save, disable autosave");
          cancelAutosave();
        });
      }
    }
    autosave();
  });
}

function cancelAutosave() {
  if (autosaveTimeout) {
    clearTimeout(autosaveTimeout);
  }
  abortController.abort();
  const infobox = document.querySelector("#ld-autosave-infobox");
  infobox.innerHTML = "Autosave cancelled";
}
