window.activeRequests = 0;

(function() {
  const originalFetch = window.fetch;

  window.fetch = function(...args) {
    //console.log("new active requeest");
    window.activeRequests++;
    return originalFetch.apply(this, args)
      .finally(() => {
        //console.log("died active request");
        window.activeRequests--;
      });
  };
})();

import Rails from "@rails/ujs";
import "channels";
require("jquery");
require("fomantic-ui");
require("cocoon-js-vanilla");

require("../src/quill_formats");
require("../src/main");
require("../src/comments");
import { startHandlingCommentsMouseOver } from "../src/comments";
require("../src/chat");
import { startUpdatingDate } from "../src/date_update";
const { startWatchingSummaries } = require("../src/summaries");
const { checkForToast } = require("../src/toast");
const { startAutosaving } = require("../src/autosave");
const { startHandlingReorder } = require("../src/reorder");
const { startHandlingHelperEditor } = require("../src/helper_editor");
import { startWatchingSplitView } from "../src/switch-view";
import { startWatchingProposals } from "../src/proposals";

Rails.start();

startWatchingSummaries();
checkForToast();
startAutosaving();
startHandlingReorder();
startHandlingHelperEditor();
startWatchingSplitView();

document.addEventListener("DOMContentLoaded", () => {
  startUpdatingDate();
  startWatchingProposals();
  startHandlingCommentsMouseOver();
});
