function createTag(tag, value, attributes = {}) {
  const span = document.createElement(tag);

  for (const key in attributes) {
    if (attributes.hasOwnProperty(key)) {
      span.setAttribute(key, attributes[key]);
    }
  }
  span.innerText = value;

  return span;
}

function smartDatetime(date, future = false) {
  const daysAgo = new Date(Date.now() - 30 * 24 * 60 * 60 * 1000);
  if (date > daysAgo) {
    return future ? timeInFutureInWords(date) : timeAgoInWords(date);
  }
  return formatDatetime(date);
}

export function createDatetimeHTML(date, future = true) {
  const str = smartDatetime(date, future);
  return `<span class="ldjs-datetime" ${future ? "data-future=\"true\"" : ""} data-date="${date}">${str}</span>`;
}

export function smartFormatDatetime(date) {
  date = new Date(date);
  const daysAgo = new Date(Date.now() - 30 * 24 * 60 * 60 * 1000);

  if (date > daysAgo) {
    const relativeDate = timeAgoInWords(date);
    return createTag("span", relativeDate, {
      "data-date": date,
      title: formatDatetime(date),
      class: "ldjs-datetime"
    });
  }
  return createTag("span", formatDatetime(date));

}

export function timeAgoInWords(date) {
  date = new Date(date);
  const seconds = Math.floor((new Date() - date) / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (days > 0) {
    return `${days} day${days !== 1 ? "s" : ""} ago`;
  }
  if (hours > 0) {
    return `${hours} hour${hours !== 1 ? "s" : ""} ago`;
  }
  if (minutes > 0) {
    return `${minutes} minute${minutes !== 1 ? "s" : ""} ago`;
  }
  if (seconds < 5) {
    return "now";
  }
  return `${seconds} seconds ago`;
}

export function timeInFutureInWords(date) {
  date = new Date(date);
  const seconds = Math.floor((date - new Date()) / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (days > 0) {
    return `${days} day${days !== 1 ? "s" : ""}`;
  }
  if (hours > 0) {
    return `${hours} hour${hours !== 1 ? "s" : ""}`;
  }
  if (minutes > 0) {
    return `${minutes} minute${minutes !== 1 ? "s" : ""}`;
  }
  return `${seconds} seconds`;
}

function formatDatetime(date) {
  return date.toLocaleString();
}
