const { reorderSidebarComments } = require("./comments");
import Quill from "quill";
import MagicUrl from "quill-magic-url";
import * as QuillTableUI from "quill-table-ui";

Quill.register("modules/magicUrl", MagicUrl);

Quill.register({
  "modules/tableUI": QuillTableUI.default
}, true);

export function isInEmptyParagraph(quill) {
  // Get the current selection in the Quill editor
  const range = quill.getSelection(true);

  console.log("current selection:',", range, range ? range.index : "<nope>");

  if (range && range.index !== null) {
    // Get the content of the current line (paragraph)
    const [line, offset] = quill.getLine(range.index);
    const text = line.domNode.textContent || "";

    // Check if the text is empty and the offset is at the start
    return text.trim() === "" && offset === 0;
  }

  return false;
}

Quill.prototype.getHtml = function() {
  return this.container.querySelector(".ql-editor").innerHTML;
};

const armFormHtmlSubmit = function(form, quill) {
  form.on("submit", function(event) {
    $("<input />").attr("type", "hidden")
      .attr("name", "page[html]")
      .attr("value", quill.getHtml())
      .appendTo(form);
    return true;
  });
};

document.addEventListener("DOMContentLoaded", function() {
  const buttons = document.querySelectorAll(".ld-splithr-button");
  buttons.forEach(function(button) {
    button.addEventListener("click", function(event) {

      const quill = window.globalQuill;
      const range = quill.getSelection(true);
      quill.insertEmbed(range.index, "splithr", true, Quill.sources.USER);
      quill.setSelection(range.index + 1, Quill.sources.SILENT);
    });
  });
});

export function makeEditor(node) {
  const icons = Quill.import("ui/icons");
  Object.keys(icons).forEach(key => delete icons[key]);

  node[0].querySelectorAll("br").forEach(function(br) {
    // Remove whitespace before <br> tag
    if (br.previousSibling && br.previousSibling.nodeType === Node.TEXT_NODE) {
      br.previousSibling.textContent = br.previousSibling.textContent.trimEnd();
    }

    // Remove whitespace after <br> tag
    if (br.nextSibling && br.nextSibling.nodeType === Node.TEXT_NODE) {
      br.nextSibling.textContent = br.nextSibling.textContent.trimStart();
    }

    const span = document.createElement("span");
    span.className = "ld-custombr";
    br.replaceWith(span);
  });

  // console.log(node[0].outerHTML);

  const quill = new Quill(node[0], {
    theme: "snow",
    modules: {
      toolbar: ".ld-html-editor-toolbar",
      magicUrl: true,
      table: true,
      tableUI: true,
    }
  });

  const tableUi = quill.getModule("tableUI");

  delete quill.keyboard.bindings["Enter"][6].shiftKey;

  quill.keyboard.addBinding({
    key: "Enter",
    shiftKey: true
  }, (range, context) => {
    console.log("shift enter");
    // const customBr = new CustomBR();

    quill.insertEmbed(range.index, "custombr", true, Quill.sources.USER);
    quill.setSelection(range.index + 1, Quill.sources.SILENT);
  });

  window.globalQuill = quill;
  const form = node.parents("form");
  armFormHtmlSubmit(form, quill);

  const quillContainer = quill.container;

  const observer = new ResizeObserver(entries => {
    $(".ui.sticky") .sticky("refresh");
  });

  quill.observer = observer;
  observer.observe(quillContainer);

  return quill;
};
